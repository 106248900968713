.App::-webkit-scrollbar {
  width: 0.66em;
}

.App::-webkit-scrollbar-track {
  background-color: rgba(100, 100, 100, 0.1);
}

.App::-webkit-scrollbar-thumb {
  border-radius: 50px;
  --tw-bg-opacity: 1;
  background-color: rgba(100, 100, 100, 0.5);
}
