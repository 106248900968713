.Scroller::-webkit-scrollbar {
  width: 0.66em;
}

.Scroller::-webkit-scrollbar-track {
  background-color: rgba(100, 100, 100, 0.1);
  border-radius: 50px;
}

.Scroller::-webkit-scrollbar-thumb {
  border-radius: 50px;
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
